<script lang="tsx">
import type { SlotsType } from 'vue'

export type BaseUiFormLabelProps<T> = {
    /**
     * The HTML tag to render the label as.
     * By default, the tag is either a `label` or a `div` depending on the presence of the `for` prop.
     */
    tag?: 'label' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    /**
     * The id of the input element that the label is associated with
     */
    for?: string
    /**
     * Whether the label is for an input that is required
     */
    required?: boolean
    /**
     * When to render the HTML element displaying the required marker.
     * This can be useful when customizing the behavior of the required marker.
     * @default 'if-required'
     */
    showRequiredMarker?: 'always' | 'if-required' | 'if-not-required'
    /**
     * Whether to render the label text in normal text style.
     */
    textNormal?: boolean
    /**
     * The id of the label element. When the id is provided, the `for` prop will be ignored and
     * the label will be rendered as a `div` element.
     */
    id?: string
    onClick?: () => void
}

type BaseUiFormLabelSlots<T> = {
    default: {}
    required: {
        isRequired: boolean
    }
}

type ComponentOptions = {}

export function defineComponentBaseUiFormLabel<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormLabelProps<T>, BaseUiFormLabelSlots<T>>) {
    return defineComponent(
        (props: BaseUiFormLabelProps<T>, ctx) => {

            const tag = computed(() => {
                if (props.tag) return props.tag
                if (props.for && !props.id) return 'label'

                return 'div'
            })

            const shouldRenderMarkerWrapper = computed<boolean>(() => {
                if (props.showRequiredMarker === 'if-required' && props.required) return true
                if (props.showRequiredMarker === 'if-not-required' && !props.required) return true
                if (props.showRequiredMarker === 'always') return true

                return !!(ctx.slots.required !== undefined || options?.slots?.required)
            })

            return () => (
                <tag.value
                    class={['sim-label', {
                        'sim-label--normal': props.textNormal,
                    }]}
                    for={props.for}
                    id={props.id}
                    onClick={props.onClick}
                    onMousedown={(e) => e.preventDefault()}
                >
                    {
                        // DEFAULT SLOT (label text)
                        renderSlot(ctx.slots.default, options?.slots?.default, {})
                    }

                    {
                        // REQUIRED MARKER
                        shouldRenderMarkerWrapper.value && (
                            <span class="sim-label__required" aria-hidden="true">
                                {
                                    renderSlot(ctx.slots.required, options?.slots?.required, { isRequired: !!props.required }, (
                                        <>
                                            {props.required ? ' *' : ' /'}
                                        </>
                                    ))
                                }
                            </span>
                        )
                    }
                </tag.value>
            )
        },
        {
            props: {
                tag: {
                    type: String as PropType<BaseUiFormLabelProps<T>['tag']>,
                    default: options?.props?.tag?.default,
                    required: options?.props?.tag?.required ?? false,
                },
                for: {
                    type: String as PropType<BaseUiFormLabelProps<T>['for']>,
                    default: options?.props?.for?.default,
                    required: options?.props?.for?.required ?? false,
                },
                required: {
                    type: Boolean as PropType<BaseUiFormLabelProps<T>['required']>,
                    default: options?.props?.required?.default ?? false,
                    required: options?.props?.required?.required ?? false,
                },
                showRequiredMarker: {
                    type: String as PropType<BaseUiFormLabelProps<T>['showRequiredMarker']>,
                    default: options?.props?.showRequiredMarker?.default ?? 'if-required',
                    required: options?.props?.showRequiredMarker?.required ?? false,
                },
                textNormal: {
                    type: Boolean as PropType<BaseUiFormLabelProps<T>['textNormal']>,
                    default: options?.props?.textNormal?.default ?? false,
                    required: options?.props?.textNormal?.required ?? false,
                },
                id: {
                    type: String as PropType<BaseUiFormLabelProps<T>['id']>,
                    default: options?.props?.id?.default,
                    required: options?.props?.id?.required ?? false,
                },
                onClick: {
                    type: Function as PropType<BaseUiFormLabelProps<T>['onClick']>,
                    default: options?.props?.onClick?.default,
                    required: options?.props?.onClick?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiFormLabelSlots<T>>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiFormLabel()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormLabel.scss" as *;

</style>
