<script lang="tsx">
import type { HTMLAttributes, SlotsType, VNode } from 'vue'
import type { BaseFormElementProps } from '@core/app/utils/form'
import { BaseUiFormLabel, BaseUiFormError, BaseUiFormDescription, BaseUiFormHelp } from '#components'
import type { FormFieldObject } from '@core-types/form'

// incomplete props
export type _BaseUiFormGroupProps = {
    help?: string
    descriptionAbove?: string
    descriptionBelow?: string

    /**
     * Whether to hide the marker for required inputs or not.
     */
    hideRequired?: boolean
    /**
     * The label of the input.
     * Will get overwritten by the default slot, if present.
     */
    label?: string
    /**
     * Whether not to render the label element.
     * An **`aria-label`** attribute will be used **in case the label element is not rendered**.
     */
    noLabelElement?: boolean
    /**
     * Whether the form group should fill the full width of the parent container.
     * @default true
     */
    fullWidth?: boolean
    /**
     * The HTML id of the div with an error message. (used when external error message handling is needed)
     */
    errorId?: string
    /**
     * The error message for the input. This will override any automatic form error messages for the field, so
     * make sure to unset it when the error is resolved.
     */
    errorMessage?: string | null

    /**
     * The HTML id of the div with the description for the input.
     * This is used to link the input with the description.
     */
    descriptionId?: string | string[]
}

type AllowUndefined<T> = {
    [P in keyof T]: T[P] | undefined
}

type BaseUiFormGroupProps =
    AllowUndefined<
        _BaseUiFormGroupProps & Omit<
            BaseFormElementProps<unknown>, 'formModifiers' | 'modelValue' | 'modelModifiers' | 'descriptionId' | 'ariaLabel'
        >
    >

export const getBaseUiFormGroupRuntimeProps = <T extends BaseUiFormGroupProps>(options?: ComponentOverrideOptions<any, T>)
: RuntimeProps<Pick<T, keyof BaseUiFormGroupProps>> =>
    defineRuntimeProps<BaseUiFormGroupProps>({
        help: { type: String },
        descriptionAbove: { type: String },
        descriptionBelow: { type: String },
        hideRequired: { type: Boolean },
        label: { type: String },
        noLabelElement: { type: Boolean },
        fullWidth: { type: Boolean },
        errorId: { type: String },
        errorMessage: { type: String },
        descriptionId: { type: [String, Array] },
        form: { type: Object },
        disabled: { type: Boolean },
        loading: { type: Boolean },
        required: { type: Boolean },
        id: { type: String },
        ariaInvalid: { type: Boolean },
    }, options)


export type BaseUiFormGroupSlots = {
    default: {
        renderLabel: (slotContent: VNode | null, labelOptions?: Partial<RenderLabelOptions>) => VNode | false
        renderAboveDescription: () => VNode | undefined
        renderBelowDescriptionAndError: () => VNode | undefined
        renderBelowDescription: () => VNode | undefined
        renderError: () => VNode | undefined
        inputId: string
        isInputRequired: boolean
        isInputAriaInvalid: boolean
        inputDescriptionIds: string | undefined
    }
    help: {}
    above: {}
    below: {}
    error: {
        message: string
    }
}

type ComponentOptions = {}

interface RenderLabelOptions {
    textNormal: boolean
    slotAbove: VNode | null
    slotBelow: VNode | null
    hasDefaultSlotContent: boolean
    class: HTMLAttributes['class']
    /**
     * The id of the label element. When the id is provided,
     * the label will be rendered as a `div` element.
     */
    labelId: string
    onClick: () => void
}

export function defineComponentBaseUiFormGroup<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormGroupProps, BaseUiFormGroupSlots>) {
    return defineComponent(
        (props: BaseUiFormGroupProps, ctx) => {
            const { injected } = useCoreUiFormProvide<any>()

            const formInputValue = computed<FormFieldObject<unknown> | undefined>(() => props.form)

            const errorMessage = computed<string | null>(() => {
                if (props.errorMessage) return props.errorMessage
                if (!formInputValue.value) return null
                if (!injected?.formErrors) return null
                // @ts-ignore
                return injected.formErrors.value[formInputValue.value?.__f] as string ?? null
            })

            const isHelpSlotRendered = computed<boolean>(() => !!(props.help || ctx.slots.help !== undefined || options?.slots?.help))
            const isErrorMessageRendered = computed<boolean>(() => !!errorMessage.value && !props.errorId)
            const isAboveSlotRendered = computed<boolean>(() => !!(props.descriptionAbove || ctx.slots.above !== undefined || options?.slots?.above))
            const isBelowSlotRendered = computed<boolean>(() => !isErrorMessageRendered.value && !!(props.descriptionBelow || ctx.slots.below !== undefined || options?.slots?.below))

            const isInputAriaInvalid = computed<boolean>(() => !!(isErrorMessageRendered.value || (props.errorId && errorMessage.value) || props.ariaInvalid))

            const inputId = props.id ?? useId()
            const helpId = useId()
            const aboveDescriptionId = useId()
            const belowDescriptionId = useId()
            const _internalErrorId = useId()
            const errorId = computed(() => props.errorId ?? _internalErrorId)

            const inputDescriptionIds = computed<string | undefined>(() => {
                const ids: string[] = []
                if (isHelpSlotRendered.value && helpId) ids.push(helpId)
                if (isAboveSlotRendered.value && aboveDescriptionId) ids.push(aboveDescriptionId)
                if (isBelowSlotRendered.value && belowDescriptionId) ids.push(belowDescriptionId)

                // automatic error
                if (isErrorMessageRendered.value && errorId.value) ids.push(errorId.value)
                // manual external error
                if (props.errorId && errorMessage.value) ids.push(props.errorId)

                if (props.descriptionId) ids.push(...(Array.isArray(props.descriptionId) ? props.descriptionId : [props.descriptionId]))

                return ids.length ? ids.join(' ') : undefined
            })

            const isInputRequired = computed<boolean>(() => {
                return props.required ?? formInputValue.value?.__r ?? false
            })

            function _renderLabel(slotContent: VNode | null, labelOptions?: Partial<RenderLabelOptions>) {
                if (labelOptions?.slotAbove || labelOptions?.slotBelow) {
                    return <div class="sim-form-group__label-wrapper">
                        {labelOptions.slotAbove}

                        <BaseUiFormLabel
                            for={!labelOptions.labelId ? inputId! : undefined}
                            class={labelOptions.class}
                            required={isInputRequired.value && !props.hideRequired}
                            textNormal={labelOptions?.textNormal}
                            id={labelOptions.labelId}
                            onClick={labelOptions.onClick}
                        >
                            {slotContent}
                        </BaseUiFormLabel>

                        {labelOptions.slotBelow}
                    </div>
                }

                return (
                    <BaseUiFormLabel
                        for={!labelOptions?.labelId ? inputId! : undefined}
                        class={labelOptions?.class}
                        required={isInputRequired.value && !props.hideRequired}
                        textNormal={labelOptions?.textNormal}
                        id={labelOptions?.labelId}
                        onClick={labelOptions?.onClick}
                    >
                        {slotContent}
                    </BaseUiFormLabel>
                )
            }

            function renderLabel(slotContent: VNode | null, labelOptions?: Partial<RenderLabelOptions>) {
                const renderLabel: boolean = props.noLabelElement ? false : !!(props.label || labelOptions?.hasDefaultSlotContent)
                return isHelpSlotRendered.value
                    ? (
                        // LABEL WITH HELP
                        <div class="sim-form-group__label-group">
                            {renderLabel && _renderLabel(slotContent, labelOptions)}

                            <BaseUiFormHelp
                                id={helpId!}
                            >
                                {renderSlot(ctx.slots.help, options?.slots?.help, {}, (
                                    <>
                                        {props.help}
                                    </>
                                ))}
                            </BaseUiFormHelp>
                        </div>
                    )
                    : (
                        // NORMAL LABEL
                        renderLabel && _renderLabel(slotContent, labelOptions)
                    )
            }

            function renderAboveDescription() {
                if (isAboveSlotRendered.value) {return (
                    // ABOVE SLOT
                    <BaseUiFormDescription
                        id={aboveDescriptionId!}
                    >
                        {renderSlot(ctx.slots.above, options?.slots?.above, {}, (
                            <>
                                {props.descriptionAbove}
                            </>
                        ))}
                    </BaseUiFormDescription>
                )}
            }

            function renderBelowDescription() {
                if (isBelowSlotRendered.value) {
                    return (
                        // BELOW SLOT (instead of error message)
                        <BaseUiFormDescription
                            id={belowDescriptionId!}
                        >
                            {renderSlot(ctx.slots.below, options?.slots?.below, {}, (
                                <>
                                    {props.descriptionBelow}
                                </>
                            ))}
                        </BaseUiFormDescription>
                    )
                }
            }

            function renderError() {
                if (isErrorMessageRendered.value) {
                    return (
                        // ERROR MESSAGE (instead of below slot)
                        <BaseUiFormError
                            error-id={errorId.value}
                        >
                            {renderSlot(ctx.slots.error, options?.slots?.error, {
                                message: errorMessage.value ?? '',
                            }, (
                                <>
                                    {errorMessage.value}
                                </>
                            ))}
                        </BaseUiFormError>
                    )
                }
            }

            function renderBelowDescriptionAndError() {
                const val = renderError()
                if (val) return val

                return renderBelowDescription()
            }

            return () => (
                <div
                    class={['sim-form-group', {
                        'sim-form-group--disabled': props.disabled,
                        'sim-form-group--loading': props.loading,
                        'sim-form-group--error': isInputAriaInvalid.value,
                        'w-full': props.fullWidth !== false,
                    }]}
                >
                    {renderSlot(ctx.slots.default, options?.slots?.default, {
                        renderLabel: renderLabel,
                        renderAboveDescription: renderAboveDescription,
                        renderBelowDescriptionAndError: renderBelowDescriptionAndError,
                        renderBelowDescription: renderBelowDescription,
                        renderError: renderError,
                        inputId: inputId!,
                        isInputRequired: isInputRequired.value,
                        isInputAriaInvalid: isInputAriaInvalid.value,
                        inputDescriptionIds: inputDescriptionIds.value,
                    })}
                </div>
            )
        },
        {
            props: getBaseUiFormGroupRuntimeProps(options),
            slots: Object as SlotsType<BaseUiFormGroupSlots>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiFormGroup()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormGroup.scss" as *;

</style>
